/* global google */

function ZoneOrderReports () {
  function initMap () {
    const lat = document.getElementById('latitude').value
    const lng = document.getElementById('longitude').value
    const rad = document.getElementById('radius').value

    if (!rad) {
      const rad = 0
      document.getElementById('radius').value = rad
    }

    const myCoords = new google.maps.LatLng(lat, lng)
    const mapOptions = {
      center: myCoords,
      zoom: 14
    }

    const map = new google.maps.Map(document.getElementById('map'), mapOptions)

    const marker = new google.maps.Marker({
      position: myCoords,
      animation: google.maps.Animation.DROP,
      map,
      draggable: true
    })

    const circle = new google.maps.Circle({
      map,
      radius: parseFloat(rad) * 1000, // multiply to 1000 to count in KM;
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: '#AA0000'
    })
    circle.bindTo('center', marker, 'position')

    // refresh marker position and recenter map on marker
    function refreshMarker () {
      const lat = document.getElementById('latitude').value
      const lng = document.getElementById('longitude').value
      const myCoords = new google.maps.LatLng(lat, lng)
      marker.setPosition(myCoords)
      map.setCenter(marker.getPosition())
      circle.bindTo('center', marker, 'position')
    }
    // when input values change call refreshMarker
    document.getElementById('latitude').onchange = refreshMarker
    document.getElementById('longitude').onchange = refreshMarker

    function refreshCircle () {
      const rad = document.getElementById('radius').value
      circle.setRadius(parseFloat(rad) * 1000)
      circle.bindTo('center', marker, 'position')
    }
    document.getElementById('radius').onchange = refreshCircle

    // when marker is dragged update input values
    marker.addListener('drag', function () {
      const latlng = marker.getPosition()
      const newlat = (Math.round(latlng.lat() * 1000000)) / 1000000
      const newlng = (Math.round(latlng.lng() * 1000000)) / 1000000
      document.getElementById('latitude').value = newlat
      document.getElementById('longitude').value = newlng
    })

    // When drag ends, center (pan) the map on the marker position
    marker.addListener('dragend', function () {
      map.panTo(marker.getPosition())
    })
  }

  function initDatePicker () {
    $('.report-daterange #start_date').datetimepicker({
      format: 'YYYY-MM-DD hh:mm A'
    })

    $('.report-daterange #end_date').datetimepicker({
      format: 'YYYY-MM-DD hh:mm A'
    })
  }

  if (window.location.pathname.includes('zone_order_reports')) {
    initMap()
    initDatePicker()
  }
}

export default ZoneOrderReports
